<template>
  <div class="aw-list">
    <div class="aw-list__heading">
      <p class="aw-list__count">
        {{ count }} {{ $t("my_achievements.result_found") }}
      </p>
    </div>
    <LxpPaginationWrapper
      ref="userlistwrapper"
      :dataFetchURL="dataFetchURL"
      :authToken="`Bearer ${$keycloak.token}`"
      nextPageLabel="next"
      :pageSizeValue="10"
      showNumberPagination
      @count="updateCount"
    >
      <template v-slot:emptyState>
        <EmptyState />
      </template>
      <template v-slot:data="slotProps">
        <div class="aw-list__data" v-for="(a, i) in slotProps.data" :key="i">
          <AchievementCard
            :title="title(a.course.content_type.slug)"
            :description="a.course.display_name"
            :type="a.course.content_type.slug"
            :achievement="a"
            @copied="onCopy"
          />
        </div>
      </template>
    </LxpPaginationWrapper>
    <Toast
      id="copy-post"
      :imgPath="require('@/assets/images/module-list/unit-complete.svg')"
      :title="$t('course.copied_to_clipboard')"
      :autoHide="false"
    />
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import { LxpPaginationWrapper } from "didactica";
export default {
  components: {
    Toast: defineAsyncComponent(() =>
      import("@/components/SelfPaced/Toast.vue")
    ),
    AchievementCard: defineAsyncComponent(() =>
      import("./AchievementCard.vue")
    ),
    EmptyState: defineAsyncComponent(() => import("./EmptyState.vue")),
    LxpPaginationWrapper
  },
  data() {
    return {
      count: 0
    };
  },
  computed: {
    ...mapGetters(["allConfig"]),
    dataFetchURL() {
      return `${this.allConfig.baseURL}/credentials/v1/user-credentials/`;
    }
  },
  methods: {
    updateCount(c) {
      this.count = c;
    },
    title(type) {
      let text;
      if (type === "course_completion_award") {
        text = this.$t("my_achievements.award_of_completion");
      } else if (type === "course_participation_confirmation") {
        text = this.$t("my_achievements.confirmation_of_participation");
      }
      return text;
    },
    onCopy() {
      this.$bvToast.show("copy-post");
    }
  }
};
</script>
<style lang="scss" scoped>
.aw-list {
  padding-top: 16px;
  .aw-list__heading {
    @include horizontal-space-between;
    padding-bottom: 8px;
    .aw-list__count {
      @include label-large($brand-public-disabled, 400);
      margin-bottom: 0;
    }
  }
  .aw-list__data {
    width: 100%;
    margin-bottom: 16px;
  }
}
</style>
<style lang="scss">
#copy-post {
  &.toast {
    border-color: $brand-success-400 !important;
    background: $brand-success-100 !important;
    .toast-header {
      align-items: center;
    }
  }
}
</style>
